.custom-jumbotron {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
  url('../../assets/images/home/elias.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding-top: 200px;
  /* Adjust as needed */
  padding-bottom: 200px;
  /* Adjust as needed */
  min-height: 90vh;
}

.title {
  font-weight: 100;
  color: white;
}

.bold {
  font-weight: 600;
}

.lead {
  font-weight: 100;
  max-width: 600px;
}

/* @media (max-width: 767px) {
  .custom-jumbotron {
   padding-top: 50px; 
   padding-bottom: 50px; 
 }
} */