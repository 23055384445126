/* custom-theme.css */

:root {
  /* Define custom theme colors */
  --primary: #313944;
  --warning: #f4ab33;
  --dark: #455b64;
  /* --secondary: #789012;
  --success: #345678;
  --info: #901234;
  
  --danger: #012345;
  --light: #abcdef;
   */
}

/* Override Bootstrap's theme colors with custom values */
/* You can adjust these as needed */
body {
  background-color: white;
  color: var(--primary) !important;
  /* Change body background color */
}
.footer-links:hover {
  color: white !important;
}
/* Change text color when input is focused */
input:focus:not(:placeholder-shown),
textarea:focus:not(:placeholder-shown) {
  color: var(--primary) !important;
  /* Change to your desired text color */
}

/* Change text color when input is not focused and has content */
input:not(:placeholder-shown),
textarea:not(:placeholder-shown) {
  color: #626b79 !important;
  /* Change to your desired text color */
}

/* Change placeholder text color */
input::placeholder,
textarea::placeholder {
  color: var(--primary) !important;
  /* Change to your desired placeholder text color */
}

.card-body {
  color: var(--primary)!important;
}
.bg-gradient-dark-sp {
   background-color: #455b64 !important;
}
.btn {
  border-radius: 4px !important;
  box-shadow: none !important;
}
.btn-primary {
  box-shadow: none !important;
}
.btn-primary:hover,
.btn.bg-gradient-primary:hover {
  background-color: #455964 !important;
  border-color: #455964 !important;
  box-shadow: 0 14px 26px -12px rgb(15 38 53 / 11%),
  0 4px 23px 0 rgb(170 208 238 / 10%),
  0 8px 10px -5px rgb(99 156 198 / 3%) !important;
}
.bg-warning {
  background-color: #f4ab33 !important;
}
.no-effect-hover:hover {
  color: white !important;
}
.shadow-primary
h2 {
  color: #455964;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 40px;
}

a:hover {
  color: var(--primary)!important;
}

.navbar-brand,
.nav-link {
  color: var(--primary);
}







body {
  background: white !important;
}
/* .navbar-toggler {
  padding: 0px;
  border: none;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border-color: transparent;
} */

.fill-gray {
  fill: #455964 !important;
}