.display-quote {
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
}

.display-8 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: normal;
}

.e-quotation-text {
  position: relative;
}

.e-quotation-text:before {
  font-family: Roboto, sans-serif;
  bottom: -12px;
  content: '"';
  left: -23px;
  top: -22px;
  position: absolute !important;
  font-size: 60px;
  color: #fabc05;
}

.e-quotation-text:after {
  font-family: Roboto, sans-serif;
  bottom: -31px;
  content: '"';
  padding-left: 2px;
  position: absolute !important;
  font-size: 60px;
  color: #fabc05;
}

quotation-yellow:before {
  color: #fabc05;
}

quotation-yellow:after {
  color: #fabc05;
}


/* Quote columns */
.quote-container {
  max-width: 1320px;
  background: transparent;
  flex-direction: row;
  max-height: 700px;
  margin: 0 auto;
  margin-top: -120px;

}

.quote-col-right {
  background: #f4ab33;
  flex: 0 0 250px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-col-left {
  background: white;
  padding: 70px 40px 70px 50px !important;
}

@media (max-width: 1024px) {
  .quote-container {
    /* height: 350px; */
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .quote-col-left {
    font-size: 15px;
  }

  .quote-col-right {
    font-size: 15px;
    height: 100%;
    flex: auto;
    padding: 30px !important;

  }
}

/* @media (min-width: 601px) {
  .quote-container {
    margin-top: -120px;
    max-height: 700px;
    max-width: 991px;
  }
} */

@media (max-width: 991px) {
  .quote-container h3 {
    font-size: 20px !important;
    line-height: 28px !important;
    position: relative;
  }

  .e-quotation-text:after {
    bottom: -9px;
    font-size: 47px;
  }

  .e-quotation-text:before {
    top: 9px;
    font-size: 47px;
  }

  .quote-col-left {
    padding: 40px !important;
  }

  .quote-col-right {
    min-height: auto;
    padding: 30px !important;
  }
}