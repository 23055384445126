body {
  color: #232a35 !important;
}

/* .text-light.m-color {
  color: #455964 !important;
  font-weight: 300 !important;
} */
.offcanvas {
  width: 250px !important;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2),
    0 16px 24px 2px rgba(0, 0, 0, .14),
    0 6px 30px 5px rgba(0, 0, 0, .12);
}

.offcanvas-body .nav-link {
  font-weight: 500;
  display: block;
  padding: 0.75rem;
}

.offcanvas-body .nav-link.active {
  background-color: #455a64;
  color: white !important;
}

.offcanvas-body {
  padding: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.navbar-toggler-bar {
  width: 26px !important;
  height: 2px !important;
}

.mat-links .nav-link {
  line-height: 36px;
  padding: 0 16px !important;
}

.mat-links a:hover {
  background: #e9e9ec;
  border-radius: 4px;
}

.mat-links .active {
  color: #fff !important;
  background-color: #455a64;
  border-radius: 4px;
}

.mat-links .active:hover {
  /* Override hover styles for active links */
  background: #455a64;
}

.mat-shadow {
  box-shadow: 4px 4px 15px 0 rgba(36, 37, 38, .08) !important;
}

.home-background {
  box-shadow: 4px 4px 15px 0 rgba(36, 37, 38, .08);
  /* background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .9) 100%) center center/cover,
    url(../img/elias-phuket.jpg) center center/cover no-repeat; */
  opacity: 1;
  content: '';
  z-index: -1;
}

.display-quote {
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
}

.display-8 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: normal;
}

.e-quotation-text {
  position: relative;
}

.e-quotation-text:before {
  font-family: Roboto, sans-serif;
  bottom: -12px;
  content: '"';
  left: -17px;
  top: -22px;
  position: absolute !important;
  font-size: 60px;
  color: #fabc05;
}

.e-quotation-text:after {
  font-family: Roboto, sans-serif;
  bottom: -31px;
  content: '"';
  padding-left: 2px;
  position: absolute !important;
  font-size: 60px;
  color: #fabc05;
}

quotation-yellow:before {
  color: #fabc05;
}

quotation-yellow:after {
  color: #fabc05;
}


/* Quote columns */
.quote-container {
  max-width: 1320px;
  background: transparent;
  flex-direction: row;
  max-height: 700px;
  margin: 0 auto;
  margin-top: -120px;

}

.quote-col-right {
  background: #f4ab33;
  flex: 0 0 250px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-col-left {
  background: white;
  padding: 70px 40px 70px 50px !important;
}

@media (max-width: 1024px) {
  .quote-container {
    /* height: 350px; */
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .quote-col-left {
    font-size: 15px;
  }

  .quote-col-right {
    font-size: 15px;
    height: 100%;
    flex: auto;
    padding: 30px !important;

  }
}

/* @media (min-width: 601px) {
  .quote-container {
    margin-top: -120px;
    max-height: 700px;
    max-width: 991px;
  }
} */

@media (max-width: 991px) {
  .quote-container h3 {
    font-size: 20px !important;
    line-height: 28px !important;
    position: relative;
  }

  .e-quotation-text:after {
    bottom: -9px;
    font-size: 47px;
  }

  .e-quotation-text:before {
    top: 9px;
    font-size: 47px;
  }

  .quote-col-left {
    padding: 40px !important;
  }

  .quote-col-right {
    min-height: auto;
    padding: 30px !important;
  }
}

/* Cards css  */
.md-projects .card {
  display: inline-block;
  max-width: 420px;
  margin: 0 8px 8px 0px;
  padding: 8px;
  box-shadow: 4px 4px 15px 0 rgba(36, 37, 38, .08);
  margin: 0 8px 20px 0px;
  border: 1px solid lightgray;
}

.md-projects .card .card-text {
  white-space: normal;
}

/* .btn.btn-default {
  margin-left: 0;
  margin-right: 0 !important;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  font-family: Roboto,
  "Helvetica Neue",
  sans-serif;
  font-size: 14px;
  font-weight: 500;
} */

hr {
  color: #c6cbd4 !important;
}



/* Scrolling wrapper  Only works when function is called JS*/
/* line 183, /home/trofika/trofika/app/assets/stylesheets/css/custom.scss */
/* .scrollable-cards {
  display: block;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
} */

/* line 190, /home/trofika/trofika/app/assets/stylesheets/css/custom.scss */
.md-projects .scrolled-row {
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;


  overflow-x: scroll;
  white-space: nowrap;
}

/* line 199, /home/trofika/trofika/app/assets/stylesheets/css/custom.scss */
.scrolled-row {
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 5px;
  background-color: white;
}

/* line 206, /home/trofika/trofika/app/assets/stylesheets/css/custom.scss */
.scrolled-row::-webkit-scrollbar:horizontal {
  height: 6px;
}

/* line 210, /home/trofika/trofika/app/assets/stylesheets/css/custom.scss */
.scrolled-row::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid #455964;
  background-color: #455964;
}

/* line 216, /home/trofika/trofika/app/assets/stylesheets/css/custom.scss */
.scrolled-row::-webkit-scrollbar {
  -webkit-appearance: none;
}

/* line 221, /home/trofika/trofika/app/assets/stylesheets/css/custom.scss */
.md-projects::-webkit-scrollbar:horizontal {
  height: 6px;
}

/* line 225, /home/trofika/trofika/app/assets/stylesheets/css/custom.scss */
.md-projects::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(240, 240, 240, 0.467);
}

/* line 230, /home/trofika/trofika/app/assets/stylesheets/css/custom.scss */
.md-projects::-webkit-scrollbar {
  -webkit-appearance: none;
}

.fa-2xl {
  width: 24px;
  height: 24px;
}

/* Hide the default dropdown on smaller screens */
@media (max-width: 991.98px) {
  .navbar-collapse {
    display: none !important;
  }
}

/* Show the Offcanvas on smaller screens */
@media (max-width: 991.98px) {
  .offcanvas {
    display: block !important;
  }
}

.bg-gradient-dark {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
}


.cookie-banner {
  position: fixed;
  bottom: 0;
  /* Adjust this value as needed */
  left: 0;
  width: 100%;
  background-color: #222f36;
  color: white;
  z-index: 9999;
  
  /* Adjust this value as needed */
}

.cookie-banner button.reject {
  margin: 0 5px;
  background: #f4ab33;
  color: #000000;
  border-radius: 4px;
  box-shadow: none;
}
.cookie-banner button.accept {
  margin: 0 5px;
  background: white;
  color: #343a41;
  border-radius: 4px;
  box-shadow: none;
}

/* Responsive styles */
@media (min-width: 768px) {
  .cookie-banner {
    /* bottom: 20px; */
    /* Adjust this value as needed */
  }
}
